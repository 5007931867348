import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface ContractListFilters {
  warehouseId?: string;
  isActive?: boolean;
  isPaid?: boolean;
  isNotified?: boolean;
  unitName?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  startDateFrom?: string;
  startDateTo?: string;
  closingDateFrom?: string;
  closingDateTo?: string;
  noticeLeaveDateFrom?: string;
  noticeLeaveDateTo?: string;
  isApplicable?: boolean;
}

export const transformContractsListFilters = ({
  selectedWarehouseId,
  isActive,
  isPaid,
  unitName,
  creationDateFrom,
  creationDateTo,
  startDateFrom,
  startDateTo,
  closingDateFrom,
  closingDateTo,
  isNotified,
  noticeLeaveDateFrom,
  noticeLeaveDateTo,
  isApplicable,
}: TableFilterType): ContractListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    isActive: isActive?.value === undefined ? undefined : isActive?.value === 'active',
    isPaid: isPaid?.value === undefined ? undefined : isPaid?.value === 'paid',
    isNotified: isNotified?.value === undefined ? undefined : isNotified?.value === 'notified',
    isApplicable: isApplicable?.value === undefined ? undefined : isApplicable?.value === 'applicable',
    unitName: unitName?.value || undefined,
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    startDateFrom: getFilterServerFormatDate(startDateFrom?.value),
    startDateTo: getFilterServerFormatDate(startDateTo?.value),
    closingDateFrom: getFilterServerFormatDate(closingDateFrom?.value),
    closingDateTo: getFilterServerFormatDate(closingDateTo?.value),
    noticeLeaveDateFrom: getFilterServerFormatDate(noticeLeaveDateFrom?.value),
    noticeLeaveDateTo: getFilterServerFormatDate(noticeLeaveDateTo?.value),
  };
};
