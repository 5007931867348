import { InvoiceStatus } from 'entities/InvoiceInsurance';
import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface InvoicesInsuranceListFilters {
  warehouseId?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  status?: InvoiceStatus;
  isApplicable?: boolean;
}

export const transformInvoicesInsuranceListFilters = ({
  selectedWarehouseId,
  creationDateFrom,
  creationDateTo,
  status,
  isApplicable,
}: TableFilterType): InvoicesInsuranceListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    status: status?.value || undefined,
    isApplicable: isApplicable?.value === undefined ? undefined : isApplicable?.value === 'applicable',
  };
};
