import React, { FC, memo, useCallback, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useGetCurrencySymbol } from 'app/appState';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { WarehouseSelect } from './WarehouseSelect';
import { InvoicesListFilterKeys } from '../../model/types';
import { InvoiceStatusSelect } from './InvoiceStatusSelect';
import { InputNumber } from 'shared/ui/InputNumber';
import { InvoiceTypeSelect } from './InvoiceTypeSelect';
import { RangeDatePicker } from 'shared/ui/RangeDatePicker/RangeDatePicker';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { RangeInput } from 'shared/ui/RangeInput/RangeInput';
import { ApplicabilitySelect } from './ApplicabilitySelect';

export const InvoicesListFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'common']);

  const currencySymbol = useGetCurrencySymbol();

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<InvoicesListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string[]) => {
      changeFilters({
        key: InvoicesListFilterKeys.selectedWarehouses,
        label: `${t('Selected')}: ${value.length}`,
        value,
        title: t('Warehouse'),
      });
    },
    [changeFilters, t],
  );

  const changeInvoicingDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesListFilterKeys.invoicingDateFrom,
        label: t('From {{invoicingDateFrom}}', { invoicingDateFrom: clientFormatDateFrom }),
        value,
        title: t('Invoicing date'),
      });
    },
    [changeFilters, t],
  );

  const changeInvoicingDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesListFilterKeys.invoicingDateTo,
        label: t('To {{invoicingDateTo}}', { invoicingDateTo: clientFormatDateTo }),
        value,
        title: t('Invoicing date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesListFilterKeys.creationDateFrom,
        label: t('From {{creationDateFrom}}', { creationDateFrom: clientFormatDateFrom }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesListFilterKeys.creationDateTo,
        label: t('To {{creationDateTo}}', { creationDateTo: clientFormatDateTo }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeDueDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesListFilterKeys.dueDateFrom,
        label: t('From {{dueDateFrom}}', { dueDateFrom: clientFormatDateFrom }),
        value,
        title: t('Due date'),
      });
    },
    [changeFilters, t],
  );

  const changeDueDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesListFilterKeys.dueDateTo,
        label: t('To {{dueDateTo}}', { dueDateTo: clientFormatDateTo }),
        value,
        title: t('Due date'),
      });
    },
    [changeFilters, t],
  );

  const changeInvoiceStatus = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: InvoicesListFilterKeys.status, label, value, title: t('Status') });
    },
    [changeFilters, t],
  );

  const changeInvoiceType = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: InvoicesListFilterKeys.invoiceType, label, value, title: t('Invoice type') });
    },
    [changeFilters, t],
  );

  const changeInvoiceAmount = useCallback(
    (value: Nullable<number>) => {
      changeFilters({ key: InvoicesListFilterKeys.invoiceAmount, label: `${value} ${currencySymbol}`, value, title: t('Total amount') });
    },
    [currencySymbol, changeFilters, t],
  );

  const changeInvoiceNumberFrom = useCallback(
    (value: Nullable<string>) => {
      changeFilters({
        key: InvoicesListFilterKeys.invoiceNumberFrom,
        label: t('From {{invoiceNumberFrom}}', { invoiceNumberFrom: value }),
        value,
        title: t('Invoice number'),
      });
    },
    [changeFilters, t],
  );

  const changeInvoiceNumberTo = useCallback(
    (value: Nullable<string>) => {
      changeFilters({
        key: InvoicesListFilterKeys.invoiceNumberTo,
        label: t('To {{invoiceNumberTo}}', { invoiceNumberTo: value }),
        value,
        title: t('Invoice number'),
      });
    },
    [changeFilters, t],
  );

  const changeContractApplicability = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: InvoicesListFilterKeys.isApplicable, label, value, title: t('Applicability') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect values={filters.selectedWarehouses?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Invoicing range')}
          tooltip={t('Shows all invoices that satisfy selected Date From range')}
          onChangeFrom={changeInvoicingDateFrom}
          onChangeTo={changeInvoicingDateTo}
          values={[
            filters.invoicingDateFrom?.value && dayjs(filters.invoicingDateFrom?.value),
            filters.invoicingDateTo?.value && dayjs(filters.invoicingDateTo?.value),
          ]}
        />

        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Creation range')}
          onChangeFrom={changeCreationDateFrom}
          onChangeTo={changeCreationDateTo}
          values={[
            filters.creationDateFrom?.value && dayjs(filters.creationDateFrom?.value),
            filters.creationDateTo?.value && dayjs(filters.creationDateTo?.value),
          ]}
        />

        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Due date range')}
          onChangeFrom={changeDueDateFrom}
          onChangeTo={changeDueDateTo}
          values={[
            filters.dueDateFrom?.value && dayjs(filters.dueDateFrom?.value),
            filters.dueDateTo?.value && dayjs(filters.dueDateTo?.value),
          ]}
        />

        <RangeInput
          type="string"
          values={[filters.invoiceNumberFrom?.value, filters.invoiceNumberTo?.value]}
          placeholder={[t('From'), t('To')]}
          label={t('Invoice number')}
          onChangeFrom={changeInvoiceNumberFrom}
          onChangeTo={changeInvoiceNumberTo}
        />

        <div className="flex space-x-3 items-center">
          <div>
            <div className="mb-2">{t('Total amount')}</div>
            <InputNumber placeholder={t('Total amount')} value={filters.invoiceAmount?.value} onChange={changeInvoiceAmount} bordered />
          </div>

          <InvoiceStatusSelect value={filters.isActive?.value} onChange={changeInvoiceStatus} onClear={clearCurrentFilters} />
        </div>
        <div className="flex space-x-3 items-center">
          <InvoiceTypeSelect value={filters.invoiceType?.value} onChange={changeInvoiceType} onClear={clearCurrentFilters} />
          <ApplicabilitySelect value={filters.isApplicable?.value} onChange={changeContractApplicability} onClear={clearCurrentFilters} />
        </div>
      </div>
    </TableFilter>
  );
});
