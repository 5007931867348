import { CreditNoteType } from 'entities/CreditNote';
import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface InvoiceListFilters {
  warehouseId?: string;
  creditedAmount?: number;
  creationDateFrom?: string;
  creationDateTo?: string;
  creditNoteType?: CreditNoteType;
  isApplicable?: boolean;
}

export const transformCreditNotesListFilters = ({
  selectedWarehouseId,
  creditNoteType,
  creditedAmount,
  creationDateFrom,
  creationDateTo,
  isApplicable,
}: TableFilterType): InvoiceListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    creditNoteType: creditNoteType?.value || undefined,
    creditedAmount: creditedAmount?.value || undefined,
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    isApplicable: isApplicable?.value === undefined ? undefined : isApplicable?.value === 'applicable',
  };
};
