export enum InvoicesInsuranceListFilterKeys {
  selectedWarehouseId = 'selectedWarehouseId',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  status = 'status',
  isApplicable = 'isApplicable',
}

export enum ContractApplicabilityFilter {
  APPLICABLE = 'applicable',
  INAPPLICABLE = 'inapplicable',
}
